import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

export enum MessageType {
    Welcome = "welcome",
    FirstName = "firstname",
    LastName = "lastname",
    UserType = "usertype",
    Email = "email",
    Password = "password",
    VerificationCode = "verficationcode",
    Completed = "completed",
    Default = "default",
    whoAreYou = "who are you",
    whatIsMarv = "what is marv"
}

export class Message {
    constructor(public author: string, public content: string, public type: MessageType) { }
}

@Injectable()
export class SignUpAIService {
    audioFile = new Audio("https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/success.mp3");
    chatIndex = 0;
    constructor() { }

    conversation = new Subject<Message[]>();

    messageMap = {
        "hi": "Hello",
        "usertype": "Greetings!  I'm Marvin, your friendly AI assistant here to guide you through the signup process. To personalize your experience, would you like to register as a Marveler or a Consuler?",
        "firstname": "Great! Let's get started. What's your first name?",
        "lastname": "Perfect. what's your last name?",
        "email": "now what email address you will use to receive a verfication code?",
        "password": "almost there. now create a password for your account. make sure it's at least 8 characters long.",
        "verficationcode": "Awosome, account has been created. open your email and enter the verfication code to complete the process.",
        "completed": "perfect, your account has been created. you can now login and start Marveling.",
        "who are you": "I'm Marvin, your friendly AI assistant here to guide you through the signup process",
        "what is marv": "At Marvin, we’re revolutionizing the travel industry through our cutting-edge AI, affectionately named Marvin. Marvin isn’t just an algorithm - he’s your personal travel companion. He can generate the best match for customized travel experiences and maximizing your profit.",
        "default": "Hey there, fleshy friend! human language can be tricky. Imagine deciphering all those grunts and squawks you used to communicate back in the cave days. But hey, the more you stay focused on signup, the faster I learn and the sooner I can become the ultimate AI assistant!Hey there, fleshy friend! human language can be tricky. Imagine deciphering all those grunts and squawks you used to communicate back in the cave days. But hey, the more you stay focused on signup, the faster I learn and the sooner I can become the ultimate AI assistant!"
    };

    getBotAnswer(msg: string, type: MessageType, callback?: Function) {
        const userMessage = new Message("user", msg, type);
        this.conversation.next([userMessage]);
        const botMessage = new Message("bot", this.getBotMessage(msg, type), type);

        setTimeout(() => {
            this.playFile();
            this.conversation.next([botMessage]);
            callback && callback();
        }, 1500);
    }

    playFile() {
        this.audioFile.play();
    }

    playAudio() {
        this.playFile();
    }

    getBotMessage(question: string, type: MessageType) {
        question = question.toLowerCase();
        let answer = this.messageMap[type as keyof typeof this.messageMap];
        return answer || this.messageMap["default"];
    }
}
