<div class="container" #scrollContainer>
<h1 class="chat-header">
    <img src="assets/icons/close-square-svgrepo-com.svg" alt="" (click)="closeChatBox()">
    MARV AI Agent</h1>
<div class="message-content"  #chatcontent [scrollTop]="chatcontent.scrollHeight+65">
    <ng-container *ngFor="let message of messages">
        <div class="message" [ngClass]="{ 'from': message.author === 'bot','to': message.author === 'user' }">
            {{ message.content }}
        </div>
    </ng-container>    
</div>
<div class="footer-input">
    <input [(ngModel)]="value" (keyup.enter)="sendMessage()" type="text" placeholder="Your message">
    <button (click)="sendMessage()">Send</button>
</div>
</div>