import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { MatMenuModule } from "@angular/material/menu";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from "@angular/animations";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
    Message,
    SignUpAIService,
    MessageType,
} from "../../shared/services/signup-ai.service";
@Component({
    selector: "app-signup-ai",
    templateUrl: "./signup-ai.component.html",
    styleUrls: ["./signup-ai.component.css"],
    standalone: true,
    imports: [
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatIconModule,
        MatTableModule,
        MatMenuModule,
        CommonModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatSelectModule,
    ],
    providers: [MatDatepickerModule, SignUpAIService],
    animations: [
        trigger("detailExpand", [
            state("collapsed,void", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*" })),
            transition(
                "expanded <=> collapsed",
                animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
            ),
        ]),
    ],
})
export class SignUpAIComponent implements OnInit {
    @ViewChild('scrollContainer') scrollContainer!: ElementRef;
    @Output() chatData = new EventEmitter<object>();
    openChat: boolean = false;

    messages: Message[] = [];
    value: string;

    constructor(public chatService: SignUpAIService, public cdRef: ChangeDetectorRef) { }

    ngOnInit() {

        this.chatService.conversation.subscribe((val) => {
            this.messages = this.messages.concat(val);
        });
        // this.chatService.getBotAnswer('I need your help with signup', MessageType.UserType);
        this.chatService.chatIndex = 1;
        this.sendMessage();
    }

    sendMessage() {
        let callback = () => {
            this.cdRef.detectChanges();
            // const messageContent: any = document.querySelector('.message-content');
            // console.log(messageContent.scrollHeight, messageContent.scrollTop);
            // messageContent.scrollTop = messageContent.scrollHeight + 150;
        };
        switch (this.chatService.chatIndex) {
            case 0:
                this.chatService.getBotAnswer("hi", MessageType.Welcome);
                break;
            case 1:
                this.chatService.getBotAnswer("I need your help with signup", MessageType.UserType, callback);
                this.chatService.chatIndex = 2;
                break;
            case 2:
                if (this.value.toLowerCase().trim() === 'marveler' || this.value.toLowerCase().trim() === 'consuler') {
                    this.chatService.getBotAnswer(this.value, MessageType.FirstName, callback);
                    this.chatService.chatIndex = 3;
                } else {
                    this.chatService.getBotAnswer(this.value, MessageType.UserType, callback);
                }
                break;
            case 3:
                if (/^[a-zA-Z]+$/.test(this.value)) {
                    this.chatService.getBotAnswer(this.value, MessageType.LastName, callback);
                    this.chatService.chatIndex = 4;
                } else {
                    this.chatService.getBotAnswer(this.value, MessageType.FirstName, callback);
                }
                break;
            case 4:
                if (/^[a-zA-Z]+$/.test(this.value)) {
                    this.chatService.getBotAnswer(this.value, MessageType.Email, callback);
                    this.chatService.chatIndex = 5;
                } else {
                    this.chatService.getBotAnswer(this.value, MessageType.LastName, callback);
                }
                break;
            case 5:
                if (/^\S+@\S+\.\S+$/.test(this.value)) {
                    this.chatService.getBotAnswer(this.value, MessageType.Password, callback);
                    this.chatService.chatIndex = 6;
                } else {
                    this.chatService.getBotAnswer(this.value, MessageType.Email, callback);
                }
                break;
            case 6:
                if (
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                        this.value
                    )
                ) {
                    this.chatService.getBotAnswer(
                        this.value,
                        MessageType.VerificationCode
                        , callback);
                    this.chatService.chatIndex = 7;
                } else {
                    this.chatService.getBotAnswer(this.value, MessageType.Password, callback);
                }
                break;
            case 7:
                if (/^\d{6}$/.test(this.value)) {
                    this.chatService.getBotAnswer(this.value, MessageType.Completed, callback);
                    this.chatService.chatIndex = 8;
                } else {
                    this.chatService.getBotAnswer(
                        this.value,
                        MessageType.VerificationCode
                        , callback);
                }
                break;
            default:
                this.chatService.getBotAnswer(this.value, MessageType.Default, callback);
        }
        this.value = "";
        // Scroll to the bottom of the page
    }

    // scrollToBottom(): void {
    //     try {
    //         this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    //     } catch (err) { }
    // }


    closeChatBox() {
        this.openChat = false;
        this.chatData.emit({ openChat: this.openChat });
    }
}
